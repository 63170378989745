jQuery( document ).ready( function ( $ ) {
	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();

		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: $( this ).serialize(),
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( 'form' ).html( '<div class="thanks full">Thanks for signing up!</div>' );
			},

			error: function ( err ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );
} );